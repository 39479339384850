// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
} from 'react-icons/fa';
// import images
import AboutImg from '../src/assets/img/about/plate.png';
import ModelWhiteImg from '../src/assets/img/model-white.png';
import ModelBlackImg from '../src/assets/img/model-black.png';
import MenuImg1 from '../src/assets/img/menu/1.png';
import MenuImg2 from '../src/assets/img/menu/2.png';
import MenuImg3 from '../src/assets/img/menu/3.png';
import MenuImg4 from '../src/assets/img/menu/4.png';
import SignatureImg from '../src/assets/img/team/signature.png';
import ChefImg from '../src/assets/img/team/chef.png';
import Avatar1 from '../src/assets/img/testimonial/dekina.png';
import Avatar2 from '../src/assets/img/testimonial/judith.png';
import Avatar3 from '../src/assets/img/testimonial/jennifer.png';

export const navData = [
  { href: '/', name: 'home' },
  { href: '/', name: 'about' },
  { href: '/', name: 'menu' },
  { href: '/', name: 'team' },
  { href: '/', name: 'testimonials' },
  { href: '/', name: 'book a table' },
  { href: '/', name: 'contact' },
];

export const heroData = {
  pretitle: 'The best Taqueria in Picayune',
  title: 'Taqueria Sol Azteca',
  subtitle:
    '13 Richardson - Ozona Rd. ',
  btnText: 'Call now',

  
};

export const socialData = [
  { href: '/', icon: <FaYoutube /> },
  { href: '/', icon: <FaFacebookF /> },

  {/* agregar mas social medias


  { href: '/', icon: <FaInstagram /> },
  { href: '/', icon: <FaPinterestP /> },
  { href: '/', icon: <FaDiscord /> },

*/}
];

export const aboutData = {
  pretitle: 'our story',
  title: 'who we are',
  subtitle:
    'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Amet consequatur, quasi minima molestiae ex exercitationem culpa quisquam architecto quaerat, iusto dolores labore, sapiente magni rem commodi aperiam ad dolorem neque ducimus. Placeat vel non quod quis pariatur a aperiam, aliquam adipisci voluptatum voluptatem sit cupiditate dolore natus beatae earum omnis.',
  btnText: 'find out more',
  image: AboutImg,
};

export const menuData = {
  title: "delicious food",
  subtitle: "authentic Mexican cuisine",
  modelImg: ModelWhiteImg,
  btnText: "view complete menu",
  menuItems: [
    {
      image: MenuImg1,
      name: "Chocolate Cake",
      price: "$7.99",
      description: "Class aptent taciti ciosqu litora torquent per",
    },
    {
      image: MenuImg2,
      name: "Veggie Burger",
      price: "$9.49",
      description: "Class aptent taciti ciosqu litora torquent per",
    },
    {
      image: MenuImg3,
      name: "King Burger",
      price: "$8.50",
      description: "Class aptent taciti ciosqu litora torquent per",
    },
    {
      image: MenuImg4,
      name: "Mexican Burger",
      price: "$9.99",
      description: "Class aptent taciti ciosqu litora torquent per",
    },
  ],
};

export const teamData = {
  pretitle: 'our team',
  title: 'meet our Owner',
  sub2: 'Im working in the food industry around the 20 years',
  name: 'Miguel',
  occupation: 'owner',
  signatureImg: SignatureImg,
  chefImg: ChefImg,
};

export const testimonialData = {
  title: "what client's say ",
  subtitle: "Statisfied clients",
  modelImg: ModelWhiteImg,
  slider: [
    {
      message:
        "The salsa and chips are delicious and the food is flavorfully seasoned, the Gorditas are yummy, and the empanada is delicious",
      image: Avatar1,
      name: "Dekina Whitney",
      occupation: "CEO of Delightful",
    },
    {
      message:
        "The food is so good. They have the best salsa in town! The decor is so bonita. The staff is friendly and always has a smile on their face. It’s an amazing place to go out with your familia.",
      image: Avatar2,
      name: "Judith Chavez",
      occupation: "CEO of Delightful",
    },
    {
      message:
        "The service is superb. The staff is so friendly and attentive. The food is amazing and the salsa is great. The food comes out fast, the servers are attentive. The restaurant itself is nice and clean. 10/10 would recommend to friends and family.",
      image: Avatar3,
      name: "Jennifer",
      occupation: "CEO of Delightful",
    },
  ],
};

export const reservationData = {
  title: "booking form",
  subtitle:
    "Call (769) 926-2412 from 10AM - 10PM daily, or book online with OpenTable.Reservations required for parties of 6 or more.",
  modelImg: ModelBlackImg,
  btnText: "make a reservation",
};

export const newsletterData = {
  title: 'join our newsletter',
  subtitle: 'Get latest news & updates in your inbox.',
  placeholder: 'Subscribe our delicious dishes',
  btnText: 'subscribe now',
};

export const footerData = {
  contact: {
    title: "contact location",
    address: "13 Richardson - Ozona Rd. Picayune, MS 39466",
    phone: "(769) 926-2412",
  },
  hours: {
    title: "working hours",
    program: [
      {
        days: "monday - friday",
        hours: "10:30 AM - 9:00 PM",
      },
      {
        days: "saturday - sunday",
        hours: "10:30 AM - 3:00 PM",
      },
    ],
  },
  social: {
    title: "social network",
    icons: [
      { href: "/", icon: <FaYoutube /> },
      { href: "/", icon: <FaFacebookF /> },
      { href: "/", icon: <FaInstagram /> },
      { href: "/", icon: <FaPinterestP /> },
      { href: "/", icon: <FaDiscord /> },
    ],
  },
};
